
export default {
    computed:{
        switchMode : {
            get(){
                return this.$store.state.layout.displayMode
            },
            set(v){
                this.$store.commit('layout/switchDisplayMode');
            }
        }
    }
}
